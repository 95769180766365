// @import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100&display=swap');

* {
  font-family: 'Mulish', sans-serif;
}

.search-box {
  background-color: #fff;
  border-radius: 50px;
  margin: 2em 0em;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  input {
    width: 90%;
    height: 2.1em;
    font-size: 18px;
    border: none;
    padding: 0em .6em;

  }

  img {
    height: 1em;
    width: 1em;
    margin-left: 1.4em;
    margin-bottom: 3px;
  }
}

.datePicorder {
  background-color: #fff;
  border-radius: 50px;
  margin: 2em 0em;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  input {
    width: 90%;
    height: 2.1em;
    font-size: 18px;
    border: none;
    padding: 0em .6em;

  }


  .date-picker-input {
    padding: 0px 15px;

  }

  img {
    height: 1em;
    width: 1em;
    margin-left: 3em;
  }
}

.datePicorder2 {
  background-color: #fff;
  border-radius: 50px;
  margin: 2em 0em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);


  input {
    width: 90%;
    height: 2.1em;
    font-size: 18px;
    border: none;
    padding: 0em .6em;
  }

  .date-picker-input {
    padding: 0px 15px;
  }

  img {
    height: 1em;
    width: 1em;
    margin-left: 3em;
  }
}



.datePicorder1 {
  background-color: #fff;
  border-radius: 50px;
  margin: 2em 0em;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  input {
    width: 90%;
    height: 2.1em;
    font-size: 18px;
    border: none;
    padding: 0em .6em;
  }

  .date-picker-input {
    padding: 0px 15px;
  }

  img {
    height: 1em;
    width: 1em;
    margin-left: 3em;
  }
}





.table-wrap {
  margin-top: 1em;
  padding: 1em;
  background-color: rgba(236, 240, 239, 0.06);

  table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    // background-color: #E9F1F9;
    // background-color: #E9F1F9;
    // background-color: #E9F1F9;
    // background-color: rgba(32, 159, 132, .06);

    th {
      padding: .5em 0em .6em 0em;
      // font-size: 16px;
      // font-weight: 600;

      // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));




      font-family: Mulish;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;

      text-align: center;
      color: #4F4F4F;

    }

    thead {
      tr {
        height: 4em;
        border-bottom: 2px solid rgb(233, 241, 249);
        font-weight: bold;
        font-family: 'Mulish', sans-serif;
      }
    }

    tbody {
      // background-color: rgba(32, 159, 132, .06);
      font-size: 14px;

      tr {
        height: 3em;
        border-bottom: 2px solid rgb(233, 241, 249);
      }

      select {
        width: 6em;
        padding-left: .5em;
        font-size: 14px;
        font-family: inherit;
      }
    }
  }
}


// manage link
.manage-link {
  color: #2D8EFF;
  font-weight: bold;
  text-decoration: underline;
  font-size: 16px;
  // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

// .title-name {
//   filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
// }

@media screen and (max-width:1000px) {

  .search-box {
    input {
      width: auto;
    }
  }

  .table-wrap {
    overflow-x: auto;

    table {
      width: 54em;
    }

    &::-webkit-scrollbar {
      width: fit-content;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, .5);
      border-radius: 20px;
      width: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

//  Default Button ::

.button {
  width: fit-content;
  margin-top: 2em;
  margin-left: auto;

  button {
    width: auto;
    padding: .5em 1.7em;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: rgba(255, 255, 255, .9);
    background-color: #333333;
    font-weight: bold;

    &:hover {
      filter: brightness(1.1);
    }
  }
}

.button2 {
  width: fit-content;
  margin-top: .2em;
  margin-left: auto;

  button {
    width: auto;
    padding: .4em .5em;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    text-transform: uppercase;
    // letter-spacing: 2px;
    color: rgba(255, 255, 255, .9);
    background-color: rgba(32, 159, 132, 1);
    font-weight: bold;
    margin-top: 5px;

    &:hover {
      filter: brightness(1.1);
    }
  }
}

.button3 {
  width: fit-content;
  margin-top: .2em;
  margin-left: auto;

  button {
    width: auto;
    padding: .4em .5em;
    font-size: 14px;
    border-radius: 4px;
    text-transform: uppercase;
    // letter-spacing: 2px;
    color: rgba(255, 255, 255, .9);
    background-color: rgb(15, 180, 144);
    font-weight: bold;
    margin-top: 5px;

  }
}


// Default pagination button::

.pagination-button {
  display: flex;
  width: fit-content;
  padding: 1em 0em 1em 0em;
  margin: auto;

  button {
    background-color: rgba(0, 0, 0, .4);
    margin: 0em 1em;
    padding: 10% 12%;
    border-radius: 4px;

    &:hover {
      background-color: rgba(32, 159, 132, 1);
    }
  }

  img {
    height: 1.5em;
    margin-bottom: -3.4px;
    filter: invert(1);
  }

  :nth-child(1) {
    img {
      transform: rotate(180deg);
    }
  }
}

.custom-file-upload {
  width: fit-content;
  margin-left: auto;

  label {
    cursor: pointer;
  }
}

@media screen and (max-width:1000px) {

  // .pagination-button{
  //   // z-index: -1;
  // }
}

.banner-img {
  img {
    height: 4em;
    border-radius: 6px;
  }
}

#short-description {
  textarea {
    height: 6em;
    width: -webkit-fill-available;
    padding: .6em 1em;
  }

  p {
    margin-top: 12px;
  }
}

#long-description {
  textarea {
    height: 8em;
    width: -webkit-fill-available;
    padding: .6em 1em;
  }
}

#img-upload {
  input {
    height: 6em;
  }

  >img {
    height: 10em;
    width: 100%;
  }
}

.alignment-justify {
  justify-content: space-between;
}

//  Detail View ::

.detail-heading {
  display: flex;
  margin-top: 2em;
  margin-bottom: 2em;

  img {
    height: 1em;
    margin-top: 5px;
    margin-right: 7px;
    // transform: rotate(90deg);
    cursor: pointer;
  }

  h3 {
    margin: 0;
    margin-left: .2em;
  }
}

.detail-body {
  display: flex;
  padding: .5em 0em .5em 2em;
  background-color: rgba(51, 51, 51, 0.05);;
  border-bottom: 2px solid rgba(255, 255, 255, .4);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  :nth-child(1) {
    width: 50%;
    // font-weight: bold;
    text-transform: capitalize;
  }

  :nth-child(2) {
    width: 80%;
  }
}

// edit button ::

.edit-icon {
  // margin-right: 1em;
  cursor: pointer;
}

.delete-icon {
  // margin-right: 1em;
  margin-left: 1em;
  cursor: pointer;
}

.delete-icon2 {
  margin-right: 2em;
  margin-left: 1em;
  cursor: pointer;
}

.eye-icon {
  height: 16px;
  width: 24px;
  cursor: pointer;
}



// Approve and remove button ::

.approve-button {
  width: fit-content;
  margin: auto;
  padding-bottom: .4em;
  border-bottom: 1px solid rgba(0, 0, 0, .3);

  button {
    width: 6em;
    height: 2em;
    font-family: inherit;
    color: #fff;
    border-radius: 3px;
    background-color: rgba(32, 159, 132, 1);

    &:hover {
      filter: brightness(1.1);
      font-weight: bold;
      cursor: pointer;
    }
  }
}

.remove-button {
  padding-top: .4em;

  button {
    width: 6em;
    height: 2em;
    font-family: inherit;
    border-radius: 3px;
    color: rgba(255, 0, 0, .9);
    border: 1.4px solid rgba(255, 0, 0, .9);
    background-color: rgba(0, 0, 0, 0);

    &:hover {
      border: 2px solid;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

// 
.collection-image {

  // margin: 0;
  img {
    height: 12em;
    width: 19em;
  }
}

// Upload  images
.upload-btn-wrapper {
  position: relative;
  // overflow: hidden;
  display: inline-block;
  height: 110px;
  margin: 0em -10px;
  width: -webkit-fill-available;
  padding: 10px;

}


// .imageInputFile {
//   display: none;
// }

.uplod-btn {
  color: rgb(106, 163, 151);
  background: rgba(0, 0, 0, .05);
  // background: rgb(146, 247, 223);
  font-weight: bold;
  height: 7em;
  width: 100%;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  cursor: pointer;

  img {
    width: 1.7em;
    height: 1.7em;
    margin-bottom: 1em;
  }
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

// Banner Warap style
.banner-wrap {
  margin-top: 3em;

  img {
    width: 100%;
    height: 26em;
  }

  // Upload  images
  .upload-btn-wrapper1 {
    position: relative;
    overflow: hidden;
    display: inline-block;
    height: 50px;
    width: 10em;

    // margin-left:1em;
    // padding: 10px;
    &.desk-banner {
      height: auto;
      width: auto;
      .uplod-btn1 {
        height: auto;
        padding: 1rem
      }
    }
  }

  .uplod-btn1 {
    color: rgba(32, 159, 132, 1);
    background: #E0E0E0;
    font-weight: bold;
    height: 5.4em;
    width: 200;
    border-radius: 6px;
    font-family: Mulish;
    font-style: normal;
    font-size: 12px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    padding: 0 1em;
    cursor: pointer;

    img {
      width: 12px;
      height: 12px;
      margin-left: 10px;
    }
  }

  .upload-btn-wrapper1 input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}

.banner-delete-button {
  button {
    font-family: inherit;
    font-size: 16px;
    border-radius: 5px;
    height: 2.6em;
    padding: 0em 2em;
    letter-spacing: 1px;
    color: #fff;
    background-color: rgba(255, 0, 0, .7);

    &:hover {
      background-color: rgba(255, 0, 0, 1);
      cursor: pointer;
    }
  }
}


//  DatePicker ::

.datepicker-alignment {
  display: flex;

  // justify-content: space-between;
  // text-align: center;
  .date-picker-input {
    cursor: pointer;
    border: 1px solid #000;
    padding: .4em 4em;
    border-radius: 5px;
    margin-right: 3em;

    // width: ;
    img {
      // height: 1em;
      // width: 1em;
      display: none;
    }
  }
}

.datePic {
  .date-picker-input {
    cursor: pointer;
    width: 26em;
    height: 25px;
    padding: 10px 8px;
    border-radius: 5px;
    // border: 1px solid #828282;
    border: 1px solid #cec5c5 !important;
    // box-shadow: 0px 0px 33px -14px rgba(0, 0, 0, .3);
    // background: rgba(0, 0, 0, .03);
    background: #ffffff !important;

    img {
      float: right;
      padding-right: 5px;
      width: 1.6em;
      height: 1.4em;
    }
  }
}

.datePic5 {
  .date-picker-input {
    cursor: pointer;
    width: 26em;
    height: 45px;
    padding: 10px 8px;
    border-radius: 5px;
    // border: 1px solid #828282;
    border: 1px solid #cec5c5 !important;
    // box-shadow: 0px 0px 33px -14px rgba(0, 0, 0, .3);
    // background: rgba(0, 0, 0, .03);
    background: #ffffff !important;

    img {
      float: right;
      padding-right: 5px;
      width: 1.6em;
      height: 1.4em;
    }
  }
}


// loader style
.loader-style {
  align-items: center;
  text-align: center;
  background-color: #E9F1F9 !important;
  width: "100%";
  height: "300";
  display: "flex";
  justify-content: "center";
  align-items: "center";
}

.network-error {
  color: red;
  font-size: 18px;
  font-weight: bold;
  margin: auto;
  text-align: center;
}


// Pagination-custom

.pagination-custom {
  width: fit-content;
  margin: auto;

  .pagination {
    margin: 0em;
    padding: 1em;
  }

  ul {
    display: flex !important;
    appearance: none !important;
    list-style-type: none !important;

    .active {
      a {
        background-color: rgba(32, 159, 132, 1);

      }
    }

    .disabled {
      a {
        background-color: rgba(0, 0, 0, .2) !important;
      }
    }

    li {
      margin: .4em;
      padding: 0em !important;
      // color: rgba(32, 159, 132, .7) !important;

    }

    a {
      text-decoration: none;
      font-size: 1em;
      background-color: rgba(32, 159, 132, .7);
      padding: .2em .5em .3em .5em;
      color: #fff;
      border-radius: 6px;

      &:visited {
        color: rgba(255, 0, 0, .7);
      }
    }
  }
}

//  Password Visibility ::
// .passlogin{
//   // position: 
// }
.eye-view-loginpage {
  // position: absolute;
  height: 1.6em;
  width: 1.6em;
  // right: 8em;
  margin-top: -2.3em;
  margin-left: auto;
  margin-right: 1em;
  background-image: url("../assets/icons/visibility.png");
  background-size: cover;
  cursor: pointer;
  opacity: .6;
}

.eye-view {
  position: absolute;
  height: 1.6em;
  width: 1.6em;
  right: 4em;
  margin-top: .4em;
  background-image: url("../assets/icons/visibility.png");
  background-size: cover;
  cursor: pointer;
  opacity: .6;
}

.visibility {
  // position: absolute;
  // height: 1.6em;
  // width: 1.6em;
  // right: 4em;
  // margin-top: .4em;
  background-image: url("../assets/icons/view.png");
  background-size: cover;
  cursor: pointer;
  opacity: .6;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
}

// image upload ::

.image-uploaded {
  >img {
    height: 12em;
    border-radius: 10px;
  }
}

// 

.collection-img {

  // max-width: 7em;
  img {
    height: 4em;
    border-radius: 7px;
  }
}


// .imageEdit{
//   height:140px;
//   width:130px;
//   border:1px solid red;
//   .edit-img-icon{
//   // margin-top: 1px;
//   // padding: 7px;
//   // right: 0;
//   // margin-right: 1;
//   // position: absolute;

//   }
// }


.image {
  position: relative;
  display: inline-block;
  margin-top: 1em;
  // height: 10em;
  // width: 10em;

  .overlay {
    position: absolute;
    right: -5em;
    top: -.5em;
    z-index: 1;
  }

  .overlay1 {
    position: absolute;
    right: -.7em;
    top: -.5em;
    z-index: 1;
  }

  .overlay2 {
    position: absolute;
    right: -.7em;
    top: -.5em;
    z-index: 1;
  }

  .overlay3 {
    position: absolute;
    // right: -.7em;
    // top: -.5em;
    margin-top: .5em;
    margin-left: 3em;
    z-index: 1;
  }

  .edit-img {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  .del-icon {
    cursor: pointer;
    height: 20px;
    width: 20px;
    margin-left: 5px;
  }

  .screen {
    height: 10em;
    width: 10em;
  }

  @media screen and (max-width: 720px) {
    .overlay {
      position: absolute;
      right: -3.6em;
      top: -1em;
      z-index: 1;
    }


  }

  @media screen and (max-width: 360px) {
    .overlay {
      position: absolute;
      right: -3.1em;
      top: -1em;
      z-index: 1;
    }

  }
}


// images wrap
.images-wrap {
  display: flex;

  .single-image {
    margin: 0 .5em;
    height: 10em;
    width: 10em;
  }
}

.select-variant {
  width: 100%;

  .select-flex {
    display: flex;
    justify-content: space-between;

    .css-2b097c-container {
      width: 100%;
      margin-right: 1em;
      margin-bottom: .2em;
    }
  }
}

.titleStyle {
  text-transform: capitalize;
}

.titleCapStyle {
  text-transform: uppercase;
}

.brandColor {
  color: rgba(32, 159, 132, 1);
  font-weight: bold;
}


// Nodata Style

.noData {
  position: relative;
  text-align: center;
  color: rgba(32, 159, 132, 1);
  font-weight: bold;
  font-size: 32px;

  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-height: 70vh;
    max-width: 100%;
    opacity: 0.3;
  }

  h4 {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: capitalize;
  }



}


.left-arrow {
  img {
    height: 1.1em;
    margin-top: 5px;
    transform: rotate(90deg);
    cursor: pointer;
  }
}

.left_arrow2 {
  img {
    height: 1.1em;
    margin-top: 5px;
    // transform: rotate(90deg);
    cursor: pointer;
  }
}


.word-counter {
  font-weight: bold;
  color: #019C0F;
}

.word-counter-error {
  font-weight: bold;
  color: #EE2404;
}

// alert button customize
.react-confirm-alert-button-group>button {
  background-color: rgba(32, 159, 132, 1) !important;
  font-weight: bold;
}


.fa-eye-style {
  // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  color: gray;
  font-size: 16px;
  font-weight: bold;
}


// .table-wrap{
//   th{
//     img{
//       height:1em;
//       margin-bottom:0px;
//     }
//   }
// }

.filter-icon {
  margin: 0em;
  height: 1em;
  margin-left: 2px;
  cursor: pointer;
  vertical-align: middle;
}

.f-icon2 {
  margin-top: -6px;
  height: 7px;
  width: 12px;
  margin-left: 4px;
  cursor: pointer;
  vertical-align: middle;
}


.f-icon {
  margin-top: 9px;
  height: 8px;
  width: 12px;
  margin-left: -12px;
  cursor: pointer;
  vertical-align: middle;
}

.capital {
  text-transform: uppercase;
}

// back-arrow
.back-arrow {
  h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    color: #303030;
  }

}

.product-name {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #303030;
}

// // Order backgrouns
// .order-wrap {
//   background-color: rgba(32, 159, 132, 0.06);

//   .card-wrap {
//     background-color: rgba(32, 159, 132, 0.06);
//     border: 1px solid rgba(1, 27, 21, 0.06) !important;

//     td {
//       background-color: transparent;
//     }
//   }
// }

// order status 

.isShipped {
  color: #EBBD02;
  font-weight: 700;
  font-size: 16px;
}

.isDelivered {
  color: #04B114;
  font-weight: 700;
  font-size: 16px;
}

.isCancelled {
  color: #F42B0C;
  font-weight: 700;
  font-size: 16px;
}

.isProgress {
  color: #F18D0E;
  font-weight: 700;
  font-size: 16px;
}




.acc {

  $midnight: #DDECF2;
  $clouds: #5c6264;

  // position: absolute;
  // opacity: 1;
  // z-index: -1;

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  /* Accordion styles */
  .tabs {
    border-radius: 1px;
    overflow: hidden;
    // box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
  }

  .tab {
    width: 100%;
    color: #4F4F4F;
    overflow: hidden;

    &-label {
      display: flex;
      justify-content: space-between;
      padding: .4em;
      background: $midnight;
      vertical-align: middle;
      // font-weight: bold;
      cursor: pointer;

      /* Icon */
      &:hover {
        background: darken($midnight, 10%);
      }

      // &::after {
      //   content: "\276F";
      //   width: 1em;
      //   height: 1em;
      //   text-align: center;
      //   transition: all .35s;
      // }
    }

    &-content {
      max-height: 0;
      padding: 0 1em;
      color: #4F4F4F;
      background: white;
      transition: all .35s;
    }

    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $midnight;
      cursor: pointer;

      &:hover {
        background: darken($midnight, 10%);
      }
    }
  }

  // :checked
  input:checked {
    +.tab-label {
      background: darken($midnight, 10%);

      &::after {
        transform: rotate(90deg);
      }
    }

    ~.tab-content {
      max-height: 100vh;
      padding: 1em;
    }
  }

}


// order-product-head
.order-head {
  background-color: rgba(5, 14, 12, 0.06);
  border-bottom: 2px solid #E9F1F9;
}

.order-product-head {
  background-color: rgba(5, 14, 12, 0.06);
  border-bottom: 2px solid #E9F1F9;
}

.order-product-head1 {
  background-color: #DDECF2;
  border-top: 2px solid rgba(5, 14, 12, 0.06);
}

// return-arrow-icon

.return-arrow-icon {
  width: 15px;
  margin-left: 1.5em;
}

// category-image
.category-image {
  width: 100px;
  height: 80px;
}

// .product-Cancelled{
//   color:#
// }

// exportLink
.exportLink {
  margin: 0em 1em 1em auto;
  padding: .3em 2em;
  cursor: pointer;
  color: #fff;
  letter-spacing: 1.3px;
  border-radius: 4px;
  background-color: rgba(32, 159, 132, .8);
}

.DateExcelfilter {
  // background-color: aquamarine;
  display: flex;
  margin-left: 5em
}



.datePicorderExcel {
  background-color: #fff;
  border-radius: 5px;
  margin: .5em 0em;
  // padding: 3em;

  input {
    width: 90%;
    height: 2.1em;
    font-size: 18px;
    border: none;
    // padding: 0em .6em;

  }


  .date-picker-input {
    padding: 0px 2px;

  }

  img {
    height: .9em;
    width: 1em;
    margin-left: .2em;
  }
}


// react-datepicker__navigation

// .react-datepicker__navigation{
//   background-color: #000000 !important;
//   border: transparent !important;
// }

.react-datepicker__navigation--previous {
  background-image: url('./../assets/icons/down_arrow2.png');
  background-size: 10px 10px;
  // background-color: #000000 !important;
  border: transparent !important;
  // border-right-color: #000000 !important;
}

.react-datepicker__navigation--next {
  background-image: url('./../assets/icons/down_arrow2.png');
  background-size: 10px 10px;
  transform: rotate(180deg);
  // background-color: #000000 !important;
  border: transparent !important;
  // border-right-color: #000000 !important;
}
// .collection-img {
//   img{
//     height: 4em;
//     border-radius: 7px;
//     object-fit: contain;
//     width: 69p  x;
//   }

// }

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn-uoload-custom{
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
// ==========================================================

@media screen and (max-width: 480px) {
  .category-menu section {    
    display: inline-table;
  }
  .menu-bar div{
    display: inherit;
    margin-bottom: 11px;
  }
  .search-box{
    margin-right: 22px;
  }
  .button button {
    font-size: 12px;
    margin-right: 25px;
    margin-top: -10px;
  }
  .button-select{
    margin-right: 25px
  }
  .form-body section{
    display: inherit!important;
    
  }
  .form-body section .two-field {
    width: 100%!important;
    margin-top: 0.5em;
    margin-bottom: 20px;
}
.form-body section div {
  width: 100%!important;
}
.radio-wrap div {
  margin-left: 0.4em;
  display: inherit!important; 
}
.radio-wrap div aside {

  margin-bottom: 9px;
}
.datepicker-alignment {
   display: inherit; 
}


}
.active{
  background-color: #FE1298;
  color: #fff;

}
.manage-order-btn{
  border-radius: 8px;
  font-weight: 600;
}
.excel-success span{
  color: #000;
}
.download-button svg{
  height: 15px;
  width: 15px;
  margin-left: 7px;
}
.download-button:hover{
  color: #fff;
  text-decoration: none;
}
.download-button{
  margin-right: 0px;
  background-color: #FE1298;
  color: #fff;
  padding: 10px 20px;
}
.button-box{
  padding: 30px 0;
}
.cross-icon:hover{
  background-color: #E9F1F9;
}
.cross-icon{
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 5px;
  position: absolute;
  right: 20px;
  top:10px;
  cursor: pointer;
}
.excel-success{
  position: relative;
}
.add-icon{
  width: 20px;
  flex: 0 0 38px;
  cursor: pointer;
}
.delete-icon2{
  width: 20px ;
  flex: 0 0 38px;
  margin-left: 10px;
}
.approved-status{
  color: #0ba819;
  // color: #019C0F;
}
.requested-status{
  color: #f58515;
  // color: #CC7722;
  // color: #FCA20C;
}
.rejected-status{
  color: #EE2404;
}