// Common Modla

.excel-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: 0.5s ease;
}

.excel-success {
  background-color: #fff;
  width: 30%;
  text-align: center;
  border-radius: 7px;
  color: rgba(32, 159, 132, 1);
  font-weight: bold;
  // margin-left: 20em;
  padding: 20px 0px;

  button {
    margin: 0em 1em 1em auto;
    padding: .3em 2em;
    cursor: pointer;
    color: #fff;
    letter-spacing: 1.3px;
    border-radius: 4px;
    background-color: rgba(32, 159, 132, .8);
  }
}




// Common Modla

.common-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: 0.5s ease;
}

.common-success {
  background-color: #fff;
  width: 30%;
  text-align: center;
  border-radius: 7px;
  color: rgba(32, 159, 132, 1);
  font-weight: bold;
  // margin-left: 20em;
  padding-top: 20px;

  button {
    margin: 0em 1em 1em auto;
    padding: .3em 2em;
    cursor: pointer;
    color: #fff;
    letter-spacing: 1.3px;
    border-radius: 4px;
    background-color: rgba(32, 159, 132, .8);
  }
}


// Success Modala
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: 0.5s ease;
}

.modal-success {
  background-color: #fff;
  width: 30%;
  text-align: center;
  border-radius: 7px;
  color: rgba(32, 159, 132, 1);
  font-weight: bold;
  margin-left: 20em;
  padding-top: 20px;

  button {
    margin: 0em 1em 1em auto;
    padding: .3em 2em;
    cursor: pointer;
    color: #fff;
    letter-spacing: 1.3px;
    border-radius: 4px;
    background-color: rgba(32, 159, 132, .8);
  }
}

// loader modal


.loader-modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(206, 199, 199, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: 0.5s ease;
}

.loader-modal-success {
  // background-color: #fff;
  width: 30%;
  text-align: center;
  border-radius: 7px;
  // color: rgba(32, 159, 132, 1);
  font-weight: bold;
  margin-left: 10em;

  button {
    margin: 0em 1em 1em auto;
    padding: .5em 2em;
    cursor: pointer;
    color: #fff;
    letter-spacing: 1.3px;
    border-radius: 4px;
    background-color: rgba(32, 159, 132, .8);
  }
}

// cropper modal
.cropper-modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(206, 199, 199, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: 0.5s ease;
}

.cropper-modal-success {
  background-color: #fff;
  width: 60%;
  text-align: center;
  border-radius: 7px;
  // color: rgba(32, 159, 132, 1);
  font-weight: bold;
  margin-left: 10em;
  padding: .6em;


  button {
    margin: 0em 1em 1em auto;
    padding: .5em 2em;
    cursor: pointer;
    color: #fff;
    letter-spacing: 1.3px;
    border-radius: 4px;
    background-color: rgba(32, 159, 132, .8);
  }

  .span {
    font-size: 12px;
    padding: 10px;
    margin-bottom: 1em;
  }
}

.ReactCrop__image {
  height: 1350px !important;
  // width: 1800px !important;
}




@media only screen and (max-width: 576px) {
  .modal-success {
    width: 90%;
    margin-left: 0em;
  }
}

@media screen and (max-width: 768px) and (min-width: 577px) {
  .modal-success {
    width: 70%;
    margin-left: 0em;
  }
}

@media screen and (max-width: 1000px) and (min-width: 769px) {
  .modal-success {
    width: 40%;
    margin-left: 0em;
  }
}

@media screen and (max-width: 1200px) and (min-width: 1001px) {
  .modal-success {
    width: 40%;
    margin-left: 15em;
  }
}
.order-model-heading{
  h5{
    font-size: 28px;
    font-weight: bolder;
  }
}
.m-btns{
  color: #fff ;
  font-weight: 700;
  border-radius: 8px;
  font-size: 20px;
}
.m-no-btn{
  background-color: #FE1298 !important;
  color: #fff !important;
}
.m-yes-btn{
  background-color: #21C306 !important;
  margin-right: 40px;
}
.model-text{
  font-size: 18px;
  font-weight: 800;
}