.cms-wrap{
  border-radius: 50px;

    .cms-title{
        font-family: Mulish;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        text-transform: uppercase;
        color: #4F4F4F;
        margin-top:1em;
    }

    .cms-body{
        margin-top: 1em;
        margin-bottom: 1em;
      border: 1px solid #cec5c5;
    }

    // override react-draft-wysiwyg editor
    .rdw-editor-main{
        background-color: white;
        border:gray !important;
        height:150px !important;
        padding: 20px 10px;
      }
    
}