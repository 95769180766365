body {
  // @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,500&display=swap');
  margin: 0;
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white  !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
span.count-style {
  font-size: 12px;
  line-height: 21px;

  position: absolute;
  top: 172px;
  right: 132px;
  margin-left: 3;

  display: inline-block;

  width: 21px;
  height: 21px;

  text-align: center;

  color: #fff;
  border-radius: 100%;
  background-color: red;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}

////============================

.grid-container {
  display: grid;
  grid-gap: 20px 20px;
  grid-template-columns: auto auto auto auto auto;
  padding: 10px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}
.dl-list {
  display: grid;
  grid-gap: 10px 10px;
  grid-template-columns: auto auto auto auto auto;
  padding: 10px;
  display: inherit !important;
}
.dl-item {
  padding: 20px;
  font-size: 30px;
  text-align: center;
}




