//  Dashboard statistics :

.dashboard-stat {
  display: flex;
  font-family: Montserrat;
  flex-direction: column;
  margin: 1.6em 1em;
  border-radius: 16px;
  background-color: #fff;
  .order-statics {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 2em;
    h3 {
      font-size: 22px;
      font-weight: bold;
      padding: 1.4em 0em 0.8em 2.1em;
      border-bottom: 2px solid #e1e1e1;
    }
    section {
      display: flex;
      justify-content: space-between;
      padding: 0.5em 3em;
      div {
        display: flex;
        width: 100%;
        margin: 1em 1em;
        color: #fff;
        flex-direction: column;
        border-radius: 10px;
        background-image: linear-gradient(
          270deg,
          #209f84,
          #209f84 130%
        );
        h4 {
          text-transform: uppercase;
          font-size: 20px;
          padding: 1em 0em 0.6em 1.3em;
          border-bottom: 1px solid rgba(255, 255, 255, 0.6);
        }
        p {
          font-size: 25px;
          font-weight: bold;
          padding: 0.4em 0em 0em 1.3em;
        }
      }
    }
  }
}

// Line chart:

.site-statics {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  // padding-bottom: 2em;
  font-family: Montserrat;
  margin: 1.6em 1em;
  background-color: #fff;
  h3 {
    text-transform: uppercase;
    font-size: 22px;
    font-weight: bold;
    padding: 1.4em 0em 0.8em 2.1em;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  }
}
.line-chart {
  padding: 1.4em;
}
//  selection
.time-filter {
  padding-top: 0.8em;
  margin-bottom: 1em;
  text-align: right;
  .vendor-year {
    background-color: #fff;
    width: 7em;
    height: 2.4em;
    margin-right: 12px;
    border-radius: 4px;
  }
  .yearly-button {
    background-color: #fff;
    margin-right: 2em;
    border-radius: 4px;
    border: none;
    p{
      margin-right: 4em !important;
      color: rgba(0, 0, 0, 0.54);
      cursor: normal;
    }
    select {
      // -webkit-appearance: none;
      outline: none;
      border: none;
      width: 10em;
      margin-top: 1em !important;

      height: 2.4em;
      padding: 0em 0em 0em 0.4em;
      border: 2px solid;
      border-radius: 4px;
      option {
        margin: 1em;
      }
    }
    i {
      color: var(--danger);
      margin-left: 6px;
    }
  }
}
.realtime-value {
  font-size: 20%;
}
//  Yearly Dropdown Button :

// Dashboard media query :

@media screen and (max-width: 1000px) {
  .order-statics {
    section {
      align-items: center;
      flex-direction: column;
      margin: 0;
    }
  }
}

//  New Password Set Modal

.setnew-pass > div:nth-child(1) {
  margin: auto;
  max-width: none;
  min-width: none;
  width: 50em;
  margin-top: 6em;
}

.new-password {
  font-family: Montserrat;
  width: 47em;
  padding: 1em 2em;
  border-radius: 80px;
  section {
    margin-left: 0.6em;
  }
  input {
    height: 3em;
  }
  label {
    text-transform: capitalize;
  }
}

.create-pass-button {
  background-color: rgb(239, 80, 77);
  color: #fff;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  border: none;
  outline: none;
  width: 75%;
  height: 3.4em;
  margin-left: 5em;
  border-radius: 30px;
}
.create-pass-button:focus {
  outline: none;
}

@media screen and (max-width: 1000px) {
}
