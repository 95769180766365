.form-wrap {
  margin: 2em .4em 3em 0em;
  padding: .3em 1.5em 1.6em 1.5em;
  // background-color: rgba(255, 255, 255, .8);
  border-radius: 20px;
  // box-shadow: 0px 0px 33px -14px rgba(0, 0, 0, .3);
}

.form-header {
  padding: 1.5em 0em;

  :nth-child(2) {
    margin-top: 1.4em;
  }

  h4 {
    font-size: 28px;
    margin: 0em;
  }

  h5 {
    font-size: 24px;
    margin: 0em;
  }

  border-bottom: 2px solid rgba(0, 0, 0, .5);
}

.form-header2 {
  padding: 1.5em 0em;

  :nth-child(2) {
    margin-top: 1.4em;
  }

  h4 {
    font-size: 24px;
    margin: 0em;
  }

}


.form-body {
  b {
    color: red;
  }

  section {
    display: flex;

    :nth-child(2) {
      margin-left: auto;
    }

    .two-field {
      width: 48%;
      margin-top: .5em;

      input {
        width: -webkit-fill-available;
        padding: .3em 0em .5em .5em;
        font-size: 16px;
        // background: rgba(0, 0, 0, .03);
        background: #ffffff;
        border: 1px solid #cec5c5;
        box-sizing: border-box;
        border-radius: 3px;
        // -webkit-box-shadow: 0px 0px 33px -14px #000000; 
        // box-shadow: 0px 0px 33px -14px rgba(0, 0, 0, .3);

        &:focus {
          border: 1px solid rgba(32, 159, 132, 1);
        }
      }

      select {
        background: #ffffff;
        border: 1px solid #cec5c5;
        height: 40px;
        // background: rgba(0, 0, 0, .03);
        // box-shadow: 0px 0px 33px -14px rgba(0, 0, 0, .3);

      }

      p {
        // font-style: normal;
        // font-weight: 600;
        // font-size: 14px;
        // color: #4F4F4F;
        margin-top: 10px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 12px;
        color: #4F4F4F;
      }

    }
  }

  .single-field {
    input {
      width: -webkit-fill-available;
      padding: .3em 0em .5em .5em;
      font-size: 16px;
      background: #ffffff;
      // background: rgba(0, 0, 0, .03); .3em 0em .5em .5em
      border: 1px solid #cec5c5;
      box-sizing: border-box;
      border-radius: 3px;
      // -webkit-box-shadow: 0px 0px 33px -14px #000000; 
      // box-shadow: 0px 0px 33px -14px rgba(0, 0, 0, .3);

      &:focus {
        border: 2px solid rgba(32, 159, 132, 1);
      }
    }

    textarea {
      font-family: inherit;
      width: -webkit-fill-available;
      padding: .7em 0em .7em 1em;
      font-size: 16px;
      // background: rgba(0, 0, 0, .03);
      background: #ffffff;
      border: 1px solid #cec5c5;
      box-sizing: border-box;
      border-radius: 6px;
      // -webkit-box-shadow: 0px 0px 33px -14px #000000; 
      // box-shadow: 0px 0px 33px -14px rgba(0, 0, 0, .3);

      &:focus {
        border: 2px solid rgba(32, 159, 132, 1);
      }
    }

    p {
      // font-style: normal;
      // font-weight: 600;
      // font-size: 14px;
      margin-top: 12px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 12px;
      color: #4F4F4F;
    }
  }

  .button {
    margin-top: 2em;
  }
}

// input[type="file" i]{
//   // background-color: aqua;
// }


//  Select Tag :
.css-yk16xz-control {
  background: #ffffff !important;
  // background: rgba(0, 0, 0, .03) !important;
  // border: 1.8px solid rgba(0, 0, 0, .8) !important;
  border: 1px solid #cec5c5 !important;
  border-radius: 4px;
  padding: .2em 0em .2em 1em;

  // &:hover {
  //   background: rgba(0, 0, 0, .03) !important;
  //   border: 1.8px solid rgba(0, 0, 0, .8) !important;
  // }
}

.css-tlfecz-indicatorContainer {
  color: rgba(0, 0, 0, 1) !important;
}

@media screen and (max-width:1000px) {
  .form-wrap {
    width: auto;
    margin: 0em 1em 0em 0em;
    padding: .3em 2em 2em 2em;
    background-color: rgba(255, 255, 255, .8);
    margin-top: 1em;
  }

  .form-body {
    section {
      div {
        width: initial;
      }
    }

  }
}

@media screen and (max-width:400px) {
  .form-body {
    section {
      div {
        width: 40%;

        p {
          font-size: 12px;
        }
      }
    }
  }
}

#select-tag {
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 12px;
    color: #4F4F4F;
    margin-top: 12px;
  }
}


// status select

.statusSelect {
  margin-top: 2em;

  .css-yk16xz-control {
    border-radius: 50px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding: 0em;
  }

  .css-1pahdxg-control {
    background-color: #ffffff;
    border-radius: 50px;
    // border: 0 px;
    box-shadow: 0 0 0 1px #ffffff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding: 0em !important;
  }

  .css-g1d714-ValueContainer {
    padding: 0 10px 0 10px;
  }
}

.statusSelectSingle {
  .css-yk16xz-control {
    border-radius: 2px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding: 0em;
  }

  .css-1pahdxg-control {
    background-color: #ffffff;
    border-radius: 2px;
    // border: 0 px;
    box-shadow: 0 0 0 1px #ffffff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding: 0em !important;
  }

  .css-g1d714-ValueContainer {
    padding: 0 10px 0 10px;
  }


}

//  Coupon Form Style ::

.radio-wrap {
  h4 {
    margin-top: 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 12px;
    color: #4F4F4F;
  }

  div {
    margin-left: .4em;
    display: flex;

    aside {
      display: flex;

      span {
        margin: 0em 2em 0em 1em;
      }
    }

    input {
      padding: 0;
      border: 0px solid rgba(32, 159, 132, 1);
    }

    input[type='radio'] {
      transform: scale(2);
    }

    input[type='radio'] {
      -webkit-appearance: none;
      margin-top: 7px;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      outline: none;
      box-shadow: 0 0 0 1px rgba(32, 159, 132, 1) !important;
      ;
    }

    input[type='radio']:before {
      content: '';
      display: block;
      width: 50%;
      height: 50%;
      border-radius: 100%;
      margin: 20% auto;
    }

    input[type='radio']:checked:before {
      background: rgba(32, 159, 132, 1) !important;
    }
  }
}

select {
  width: 100%;
  outline: none;
  height: 2em;
  margin: auto;
  font-size: 18px;
  padding-left: 1em;
  background: #FFFFFF;
  border: 0.5px solid #828282;
  border-radius: 4px;
  color: #828282;

  option {
    color: #828282;
  }
}

.changePass-wrap {
  background-color: #fff;
  margin: 1.4em 0em 0em 0em;
  padding: 1em 2em;
  border-radius: 10px;
  box-shadow: 0px 0px 33px -14px rgba(0, 0, 0, .3);

  section {
    width: 100%;
    margin-bottom: 2em;

    div {
      display: flex;
    }

    input {
      width: -webkit-fill-available;
      border-radius: 6px;
      border: 2px solid rgba(0, 0, 0, .5);
      font-size: 18px;
      padding: .4em 1em;
      box-shadow: 0px 0px 33px -14px rgba(0, 0, 0, .3);
    }
  }
}

.error {
  color: red;
  font-size: 14px;
}


// place holder style
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #4F4F4F;
}


.button-variant {
  button {
    color: rgba(32, 159, 132, 1);
    border: 2px solid rgba(32, 159, 132, 1);
    font-weight: bold;
    font-family: Roboto;
    font-size: 15px;
    border-radius: 5em;
    padding: .6em 3.1em;
    margin-top: 1em;
    margin-bottom: 1em;
    cursor: pointer;
  }
}

.cropper-button {
  button {
    color: rgba(32, 159, 132, 1);
    border: 2px solid rgba(32, 159, 132, 1);
    font-weight: bold;
    font-family: Roboto;
    font-size: 15px;
    border-radius: 5em;
    padding: .5em 2.1em;
    margin-top: 1em;
    margin-bottom: 1em;
    cursor: pointer;
  }
}

// form label
.label-form {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #4F4F4F;
}