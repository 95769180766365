// .page-wrapper {
//   display: block;
//   position: relative;
// }

// @media (min-width: 1200px) {
//   .content {
//     width: calc(100% - 240px);
//     margin-left: auto;
//   }
// }

// order status

.order .order-status {
  overflow-x: auto;
  margin-top: 3%;
  background-color: var(--white);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  ul {
    display: flex;
    vertical-align: middle;
    padding: 20px 30px 0px 30px;
    list-style-type: none;
    li {
      margin-right: 30px;
      padding: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 130%;
      text-transform: uppercase;
      border-bottom: 2px solid var(--white);
      font-family: Montserrat;

      span {
        padding: 5px;
        margin-left: 5px;
        background-color: var(--light-dark);
        border-radius: 100%;
        color: var(--white);
        font-size: 12px;
      }
      &:hover {
        color: var(--danger);
        border-bottom: 2px solid var(--danger);
        cursor: pointer;
        span {
          background-color: var(--danger);
        }
      }
    }
  }
}

// Order Product List Table
.boort{
    // @import  "~bootstrap/dist/css/bootstrap.min.css";
}
.order tbody tr {
  background-color: var(--light-danger);
  border-top: 1px solid var(--white);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  padding: 50px !important;
  .id-details {
    color: var(--danger);
    font-size: 14px;
    font-weight: bold;
  }

  td {
    vertical-align: middle;
    color: var(--dark);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
}

.scrollme {
  overflow-x: auto;
}

// Order Details
.order-details {
  border-radius: 7px;
  .hr {
    border: 1px solid var(--light);
  }
  .heading {
    font-weight: bold;
    color: var(--dark);
    margin: 10px 0px 5px 0px;
  }
}

// item-detail

.item-detail {
  th {
    font-weight: bold;
    font-size: 16px;
  }
  td {
    background-color: var(--white);
    text-align: left !important;
    font-size: 16px !important;
    color: var(--dark) !important;
  }
  span {
    margin-left: 5px;
  }
  h6 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 116.6%;
    margin: 0;
    padding: 0;
    color: var(--dark);
  }
}

// ##### heading and value on order details pages #####
.content-p-h6 {
  p {
    font-size: 14px;
    color: var(--light-dark);
    padding: 0 !important;
    margin: 0 !important;
  }
  h6 {
    padding: 0 !important;
    color: var(--dark);
  }
  .general-content {
    // margin: 5px 0px;
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
  }
}

.select-form-control {
  border: 0;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
  // width: 70%;
  height: 80%;
  font-size: 14px;
}

// .nav-link {
//   color: var(--danger) !important;
// }

.order-details {
  i {
    cursor: pointer;
  }
}

.order-chart-view {
  h6 {
    font-weight: bold;
    margin-left: 20px;
    margin-bottom: 15px;
  }
  .h-line {
    height: 1px;
    border: 1px solid rgb(218, 221, 220);
  }
  th {
    padding: 5px;
    font-weight: bold;
  }
  td {
    padding: 5px;
    // font-weight: bold;
    font-size: 16px;
  }
}

.order-list {
  th {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #4f4f4f;
  }
}

@media only screen and (max-width: 557px) {
  .date-fields {
    width: 210px;
  }
}
.orderDetail-table{
  &.table {
    td {
      vertical-align: middle;
    }
  }
}