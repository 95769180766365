
.menu-bar{
  width: 96%;
  // margin: auto;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 2em;
  padding: 1.4em 0em 1em 0em;
  section{
  display: flex;
  width: 70%;
  justify-content: space-around;
  text-transform: uppercase;
  a{
    color: #000;
  }
}
  div{
    font-weight: 500;
    padding-bottom: .4em;
    cursor: pointer;
    &:hover{
      border-bottom: 2px solid rgba(32,159,132,1);
      margin-top: -2px;
    }
  }
}
.active-menu{
  // div{
    color: rgba(32,159,132,1);
  border-bottom: 2px solid rgba(32,159,132,1);
  margin-top: -2px;
// }
}

.category-menu{
  section{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    text-transform: uppercase;
    div{
      margin-left: 6%;
    }
  }
}