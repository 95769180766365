.page-wrapper {
    display: block;
    position: relative;
  }

  @media (min-width: 1200px) {
    .content {
      width: calc(100% - 240px);
      margin-left: auto;
    }
  }


.setting-menu{
  overflow-x: auto;
  margin-top: 3%;
  background-color: var(--white);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  ul{
    display: flex;
    vertical-align:middle;
    padding: 20px 30px 0px 30px;
    list-style-type: none;
    li{
      margin-right:30px;
      padding: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 130%;
      text-transform: uppercase;
      border-bottom:2px solid var(--white);
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      span{
        padding: 5px;
        margin-left: 5px;
        background-color: var(--light-dark);
        border-radius: 100%;
        color: var(--white);
        font-size: 12px;
      }
     
      &:hover{
        color:var(--danger);
        border-bottom:2px solid var(--danger);
        cursor: pointer;
        span{
        background-color: var(--danger);
        }
      }
    }
  }
}

.isActive{
  color:var(--danger);
  border-bottom:2px solid var(--danger) !important;
  cursor: pointer;
  span{
  background-color: var(--danger);
  }
}

.setting-info{
  margin-top:3%;
  .profile{
    background-color: var(--white);
    .row-style{
        padding: 20px 30px 0px 30px;
    }
    h3{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #4F4F4F;
      img{
        cursor: pointer;
      }
      .bank-submit{
        color: var(--danger);
        cursor: pointer;
      }
      span{
        color:var(--danger);
        font-size: 16px;
        margin-left: 10px;
        font-weight: bold;
        cursor: pointer;
      }
     
    }
    .hr-line{
      background: #C7CEDC;
      border-radius: 2px;
    }
    p{
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
    }
    h4{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
    }
    .fa-edit-icon{
      color:var(--danger);
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
    }
    span{
      cursor: pointer;
      color: #333333;
    }
    .ok-check{
      font-size: 16px;
      cursor: pointer;
      color: #333333;
    }
    .setting-form{
      border:0;
      border-radius: 0px !important;
      border-bottom:1px solid #B7B7B7;
    }
    .input-group-text{
      background-color: #ffffff;
      border:0;
    }
  }


  .password-setting{
  .forgot-password{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    background-color: var(--white);
    width:100%;
    input {
      font: small-caption;
      font-size: 16px;
    }
    h3{
      padding: 6% 5% 1% 5%;
      text-align: left;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #4F4F4F;
      
    }
    
    .input-group99{
      border-bottom: 1px solid #B9B9B9;
    }
    .input-group-text99{
      border:0px;
      background-color: transparent;
    }
    .form-control-password-change{
      border:0px;
      color: #B9B9B9;
      &::placeholder{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px !important;
        line-height: 20px;}
        color: #4F4F4F;
    }

  }
  }

  .banking{
    background-color: var(--white);
    .row-style{
        padding: 20px 30px 0px 30px;
    }
    h3{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #4F4F4F;
     img{
       cursor: pointer;
     }
     .bank-submit{
       color: var(--danger);
       cursor: pointer;
     }
     span{
       color:var(--danger);
       font-size: 16px;
       margin-left: 10px;
       font-weight: bold;
       cursor: pointer;
     }
    }
    .hr-line{
      background: #C7CEDC;
      border-radius: 2px;
    }
    p{
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
    }
    h4{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
    }
    .fa-edit-icon{
      color:var(--danger);
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
    }
    span{
      cursor: pointer;
      color: #333333;
    }
    .ok-check{
      font-size: 16px;
      cursor: pointer;
      color: #333333;
    }
    .setting-form{
      border:0;
      border-radius: 0px !important;
      border-bottom:1px solid #B7B7B7;
    }
    .input-group-text{
      background-color: #ffffff;
      border:0;
    }
  }


}

.img-icon{
  img{
    cursor: pointer;
    margin-left:5px;
  }
}


.action_button{
  text-align: right;
  svg{
    cursor: pointer;
  }
  .savebutton{
    background-color: #D1E9DC;
    border-radius: 4px;
    color:#219653;
    border:0;
    width: 90px;
    height: 35px;
    font-weight: bold;
outline: none !important;
    margin-left: 3%;
  }
  .cancelBtn{
    background: #FBDBDA;
    font-weight: bold;
    border-radius: 4px;
    color:#EF504D;
    border:0;
    width: 90px;
    height: 35px;
    outline: none !important;
 
  }
}
.business-style{
    font-family: Montserrat;
    display: flex;
    padding: 1em 2em;
    justify-content: space-between;
}
.address-style{
  p{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #4F4F4F;
  }
}
.docs-style{
  p{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #4F4F4F;
  }
}

.delete-docs{
  color:var(--danger);
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.upload-new-file{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #4F4F4F;
}

// .setting{
  .modal-close-button .close{
    color:var(--danger) !important;
  }
// }


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}