.login-wrap{
  display: flex;
}
.login-art{
  width: 45%;
  height: 100vh;
  padding: 0em 3em;
  img{
    height: 320px;
    width: 299.5px;
    margin-left: 110px;
    // height: auto;
    // width: 100%;
    // margin: 25% auto;
  }
  h1{
    width: fit-content;
    margin: 16% auto;
    font-size: 250%;
    color: rgba(32,159,132,.8);
    b{
      color: rgba(32,159,139,1);
    }
  }
}
.loginform-wrap{
  width: 50%;
  background-color: #fff;
}
//  Removing Defaults :: 
input {
  background: none;
  outline: none;
  &:focus{
    background: none;
    outline: none;
  }
}

a{
  color: none;
  text-decoration: none;
  &:hover{
    color: none;
    text-decoration: none;
  }
  &:focus{
    color: none;
    text-decoration: none;
  }
}
button{
  background-color: none;
  border: none;
  outline: none;
  &:focus{
    background-color: none;
    border: none;
    outline: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  box-shadow: 0 0 0 30px #fff inset !important;
}

/* ! Login-Form */

.login-form{
  display: flex;
  flex-direction: column;
  width: 28em; 
  margin: auto;
  margin-top: 24%;
  height: 22.6em;
  justify-content: space-between;
  h3{
    margin: .4em 0em;
    font-size: 40px;
    font-weight: 500;
  }
  p{
    margin: 1em 0em;
    padding: 0;
    font-weight: 500;
    b{
      color: rgba(255,0,0,.8);
    }
  }
  input{
    width: -webkit-fill-available;
    font-size: 16px;
    height: 2.5em;
    padding-left: 1em;
    border: 1px solid rgb(130,130,130);
    border-radius: 6px;
  }
  button{
    margin-top: 2em;
    font-size: 16px;
    height: 2.8em;
    letter-spacing: 2px;
    width: 100%;
    color: #fff;
    background-color:rgba(32,159,132,1);
    border-radius: 6px;
    cursor: pointer;
    &:hover{
      filter: brightness(1.1);
    }
  }
  section{
    display:flex;
    align-items: baseline;
    justify-content: space-between;
    a{
      color: rgba(255,0,0,.8);
    }
  }
}


.error-msg{
  color: rgba(255,0,0,.8);
  margin: 0em;
  padding: 0em 0em 0em 0.2em;
  font-weight: bold;
  margin-bottom: -20px;
}

// forgot form ::

.forgot-form{
  display: flex;
  flex-direction: column;
  width: 28em; 
  margin: auto;
  margin-top: 24%;
  height: 21em;
  justify-content: space-between;
  h3{
    margin: .4em 0em;
    font-size: 40px;
    font-weight: 500;
    img{
      height: .5em;
      transform: rotate(90deg);
      margin-top: -3px;
      margin-left: -33px;
      margin-right: 8px;
      opacity: .7;
    }
  }
  h5{
    font-size: 20px;
    margin-top: -1em;
    margin-bottom: .3em;
  }
  p{
    margin: 1em 0em;
    padding: 0;
    font-weight: 500;
    b{
      color: rgba(255,0,0,.8);
    }
  }
  input{
    width: 95%;
    font-size: 16px;
    height: 2.5em;
    padding-left: 1em;
    border: 1px solid rgb(130,130,130);
    border-radius: 6px;
  }
  button{
    margin-top: 1em;
    font-size: 16px;
    height: 2.8em;
    letter-spacing: 2px;
    width: 100%;
    color: #fff;
    background-color:rgba(32,159,132,1);
    border-radius: 6px;
    cursor: pointer;
    &:hover{
      filter: brightness(1.1);
    }
  }
  section{
    display:flex;
    align-items: baseline;
    justify-content: space-between;
    a{
      color: rgba(255,0,0,.8);
    }
  }
}

.reset-form{
  display: flex;
  flex-direction: column;
  width: 28em; 
  margin: auto;
  margin-top: 24%;
  height: 25em;
  justify-content: space-between;
  h3{
    margin: .4em 0em;
    font-size: 40px;
    font-weight: 500;
    img{
      height: .5em;
      transform: rotate(90deg);
      margin-top: -3px;
      margin-left: -33px;
      margin-right: 8px;
      opacity: .7;
    }
  }
  h5{
    font-size: 20px;
    margin-top: -1em;
    margin-bottom: .3em;
  }
  p{
    margin: 1em 0em;
    padding: 0;
    font-weight: 500;
    b{
      color: rgba(255,0,0,.8);
    }
  }
  input{
    width: 95%;
    font-size: 16px;
    height: 2.5em;
    padding-left: 1em;
    border: 1px solid rgb(130,130,130);
    border-radius: 6px;
  }
  button{
    margin-top: 1em;
    font-size: 16px;
    height: 2.8em;
    letter-spacing: 2px;
    width: 100%;
    color: #fff;
    background-color:rgba(32,159,132,1);
    border-radius: 6px;
    cursor: pointer;
    &:hover{
      filter: brightness(1.1);
    }
  }
  section{
    display:flex;
    align-items: baseline;
    justify-content: space-between;
    a{
      color: rgba(255,0,0,.8);
    }
  }
}

@media screen and (max-width: 480px) {
  .login-wrap{
    display: inline-block;
  }
  .login-art{
    width: 100%;
    padding: 0em 2em;
  }
  .login-art img{
    margin-left: auto;
  }
  .login-form{
    margin-top: 0px;
    padding: 0px 20px;
  }



}