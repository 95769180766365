.page-container {
  display: flex;
}

.sidebar-left-fix {
  background-color: rgba(255, 255, 255, 1);
  // width: 24%;
  width: 292px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 100%;
  position: fixed;
  -webkit-box-shadow: 0px 0px 42px -30px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 42px -30px rgba(0, 0, 0, 0.2);
}

.sidebar-wrap {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  color: #333333;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.08);;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .5);
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.sidebar-user {
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, .4);

  :nth-child(1) {
    width: 45%;
    text-align: right;
    margin: .5em -.6em;
    padding-right: 1em;

    img,svg {
      height: 54px;
      width: 70px;
    }
  }

  :nth-child(2) {
    text-align: left;
    // margin: 1.8em 0em;
    margin: 2.7em 0em 0em -8px;
    font-family: inherit;
    font-size: 14px;
  }
}

.sidebar-links {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  justify-content: space-around;
  padding-bottom: 2em;
  text-transform: uppercase;

  a {
    color: #333333;
  }

  div {
    margin: .3em 5% .3em 6%;
    padding: .4em 0em .4em 1.3em;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    border-radius: 6px;

    // section{
    //   padding: 1em 0em 1em 1.4em;
    //   p{
    //     margin: 0;
    //   }
    // }
    &:hover {
      color: #333333;
      background-color:rgba(51, 51, 51, 0.1);
    }
  }
}

.active-link {
  div {
    color: #333333;
    background-color:rgba(51, 51, 51, 0.1);
  }
//  
  // section{
  //   border-radius: 6px;
  //   opacity: .8;
  //   background: linear-gradient(170deg, rgba(32,159,132,1) -190%, rgba(255,255,255,.7) 50%, rgba(32,159,132,1) 220%);
  //   transform: rotate(4deg);
  //   p{
  //     margin: 0;
  //     opacity: 1;
  //     transform: rotate(-4deg);
  //   }
  // }
}

.dropdown-btn {
  display: flex;

  // background-color: rgba(255,255,255,.2);
  section {
    margin-left: auto;
    margin-right: 1em;

    img {
      height: 1em;
      width: 1em;
      // filter: invert(1);
      filter: brightness(1);
      margin-bottom: -2px;
    }
  }

  &:hover {
    img {
      filter: brightness(1);
    }
  }
}

.dropdown-body {
  width: 90%;
  margin-left: auto;
  margin-bottom: .4em;
}

// dropdown active::
.dropdown-active {
  display: flex;

  // color:rgba(32, 159, 132, 1) ;
  // background-color: rgba(32, 159, 132,);
  section {
    margin-left: auto;
    margin-right: 1em;

    img {
      height: 1em;
      width: 1em;
      transform: rotate(180deg);
      margin-bottom: -2px;
    }
  }

}

.burger {
  display: none;
  position: absolute;
  display: none;
  margin-top: 2em;
  margin-left: 2em;
  cursor: pointer;
  z-index: 2;

  div {
    width: 25px;
    height: 3px;
    background-color: rgba(0, 0, 0, .6);
    margin: 5px;
    transition: all 0.3s ease;
  }
}

.right-side {
  // width: 73%;
  width: calc(100% - 292px);
  margin-left: auto;
  padding: 0em 1em;
  // overflow-x: hidden;
}

// Media Query ::

@media screen and (max-width:1000px) {
  .sidebar-left-fix {
    width: 0px;
    background-color: none;
    border-radius: 0px;
    position: initial;
  }

  .right-side {
    width: 100%;
    margin: 0em;
    padding: 3em 0em 0em 1em;
    // overflow-x: hidden;
  }

  .sidebar-user {
    // flex-direction: column;
    border-bottom: 1px solid rgba(255, 255, 255, .4);

    :nth-child(1) {
      // width: 82%;
      // text-align: right;
      // margin: 0.4em 1em 0.3em auto;
    }

    :nth-child(2) {
      width: fit-content;
      text-align: left;
      margin: 1em 1em 1em auto;
    }
  }

  .sidebar-wrap {
    position: fixed;
    width: 100%;
    z-index: 2;
    height: -webkit-fill-available;
    transform: translateX(-100%);
    transition: transform 0.4s ease-in-out;
  }

  .burger {
    display: block;
    margin-left: 1em;
    z-index: 3;
    // position: relative;
  }

  .sidebar-active {
    transform: translateX(0%);
  }

  .toggle {
    .line1 {
      transform: rotate(-45deg) translate(-5px, 6px);
      background-color: rgba(12, 11, 11, 0.8);
    }

    .line2 {
      opacity: 0;
    }

    .line3 {
      transform: rotate(45deg) translate(-5px, -6px);
      background-color: rgba(12, 11, 11, 0.8)
    }
  }
}