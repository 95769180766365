.specification{
  // display: flex;
  margin-top: 1em;
  .row{
    display: flex;
  }
  .col-md-5{
    margin-right: 1em;
  }
  .cross_button{
    font-family: inherit;
    background-color: inherit;
    font-size: 24px;
    padding-top: .6em;
    color: rgb(255,0,0);
    font-weight: bold;
    cursor: pointer;
  }
  .add_button{
    button{
      font-family: inherit;
      margin-top: .6em;
      font-size: 16px;
      padding: .6em 4.6em;
      border-radius: 7px;
      color: #fff;
      cursor: pointer;
      background-color: rgba(32, 159, 132,.7);
      &:hover{
        filter: brightness(1.15);
      }
    }
  }
}
.brand-image-upload{
  div{
    // width: 2em;
    margin: 0;
    // padding: 1em;
  >img{
    margin: 0;
    height: 2em;
    width: 12em;
    border-radius: 8px;
  }
}
}